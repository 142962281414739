@media only screen and (width >= 992px) and (width <= 1199px) {
  .welcome-content h2 {
    font-size: 42px;
  }

  .timelineBody {
    max-height: 545px;
  }

  .team-member-thumb {
    width: 150px;
  }

  .post-meta p a {
    margin-right: 15px;
  }

  .post-title h4 {
    font-size: 20px;
  }

  .single-team-member {
    padding: 20px;
  }

  .cd-intro.default-title > h2 {
    font-size: 42px;
  }
}

@media (width >= 1200px) {
  .hidden-lg {
    display: none;
  }
}

@media (width <= 992px) {
  .hidden-md {
    display: none;
  }
}

@media (width <= 767px) {
  .hidden-sm {
    display: none;
  }
}

@media (width <= 480px) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .welcome_area {
    height: 700px !important;
  }

  .welcome_single_slider.height-900 {
    height: 700px;
  }

  .welcome-content h2, .cta-content h2 {
    font-size: 30px;
  }

  .timelineBody {
    max-height: 640px;
  }

  .price_table_text > h1 {
    font-size: 36px;
  }

  .price_table_text > p, .table_text_details > p {
    font-size: 13px;
  }

  .login-btn {
    margin: 30px;
  }

  .cd-intro.default-title > h2 {
    font-size: 36px;
  }
}

@media only screen and (width <= 767px) {
  .login-btn {
    margin: 30px;
  }

  .welcome-content p {
    font-size: 14px;
  }

  .single_cool_fact {
    margin-bottom: 50px;
  }

  .classy-navbar {
    height: 60px;
  }

  .who-we-contant h4, .cta-content h2 {
    font-size: 24px;
  }

  .cta-content p {
    font-size: 14px;
  }

  .timelineBox {
    margin-top: 100px;
  }

  .our-mission-area {
    height: 1000px !important;
  }

  .section-heading h2 {
    font-size: 24px;
  }

  .post-meta p a {
    margin-right: 15px;
  }

  .post-title h4 {
    font-size: 20px;
  }

  .footer-side-thumbnail {
    width: 100%;
  }

  .welcome_single_slider.height-900 {
    height: 600px;
  }

  .welcome_area.sliders {
    height: 600px !important;
  }

  .cd-intro.default-title > h2 {
    font-size: 24px;
  }

  .welcome_area.animated-headline {
    height: 600px !important;
  }

  .dream-btn.video-btn {
    min-width: 70px;
    padding: 0;
    font-size: 20px;
  }

  #scrollUp {
    bottom: 100px;
    right: 0;
  }

  .welcome-video-area {
    margin-top: 0;
  }

  .timelineBody .timeline {
    padding: 1em;
  }

  .timelineBody .timeline .timelineDate {
    flex: 0 0 50px;
    padding: 0;
  }

  .footer-copywrite-info {
    text-align: left;
  }

  .breadcumb--con {
    padding-top: 60px;
  }

  .welcome-content {
    margin-top: 130px;
  }

  .sidebar-area {
    margin-top: 100px;
  }

  .welcome_area {
    height: 1100px !important;
  }

  .welcome_area.none {
    height: 100vh !important;
  }

  .sonar {
    transform: scale(1);
  }

  .welcome-content h2 {
    font-size: 30px;
  }

  .dream-btn {
    min-width: 160px;
    padding: 0 20px;
    font-size: 12px;
  }

  .single-team-member:after {
    width: 50%;
  }
}

@media only screen and (width >= 576px) and (width <= 767px) {
  .welcome-content h2 {
    font-size: 36px;
  }

  .dream-btn {
    min-width: 160px;
    padding: 0 20px;
    font-size: 12px;
  }

  .cd-intro.default-title > h2 {
    font-size: 30px;
  }

  .single-team-member:after {
    width: 80%;
  }
}
/*# sourceMappingURL=index.dee486be.css.map */
